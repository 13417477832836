import Header from '../Header';
import React, { useEffect, useState } from 'react';
import Footer from '../Footer';
import axios from 'axios';
import ImageGallery from 'react-image-gallery';
import '../../styles/image-gallery.css';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useBasketStore } from '../../store/Basket';
import Basket from '../Basket';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Helmet } from 'react-helmet';
import { BASE_URL, incrementProductViewCount } from '../../shared/constants.js';
import { fetchProductStates } from '../../utils/apiUtils';
import {
  defineOrderStatusClassName,
  defineProductStateClassName,
  handleOrderStatusChange,
} from '../../utils/adminOrderService';

const ProductPage = props => {

  const addProductToCart = useBasketStore(state => state.addProduct);
  const patchProduct = useBasketStore(state => state.patchProduct);

  //enums
  const [productStatesEnum, setProductStatesEnum] = useState([]);

  const [productInfo, setProductInfo] = useState({});
  const [carouselImages, setCarouselImages] = useState([]);
  const [user, setUser] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const token = localStorage.getItem('token');

  const [productIdsInCart, setProductIdsInCart] = useState([]);
  const cartProducts = useBasketStore(state => state.products);

  const [showModal, setShowModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showThumbnails, setShowThumbnails] = useState(true);
  const Toggle = () => setShowModal(!showModal);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem('currentUrl', window.location.pathname);
    getProductInfo();
    defineQuantityFromCart();
    if (!token) {
      return;
    }
    axios
      .get('/api/v1/auth/current-user', {
        headers: {
          Authorization: token,
        },
      })
      .then(res => {
        if (res.status === 200) {
          setUser(res.data.data);
          setIsLoggedIn(true);
        }
      }).catch(function(error) {
    });
  }, []);

  useEffect(() => {
    const productIdsInCart = cartProducts.map(product => product.id);
    setProductIdsInCart(productIdsInCart);
    defineQuantityFromCart(productInfo.id);
    fetchProductStates(token, setProductStatesEnum);
  }, [cartProducts]);

  useEffect(() => {
    if (productInfo && productInfo.name) {
      document.title = `Купити ${productInfo.name} з доставкою по Україні.`;
    }
  }, [productInfo]);

  const defineQuantityFromCart = (currentProductId) => {
    const cartProduct = cartProducts.find(cartProduct => cartProduct.id === currentProductId);
    if (cartProduct !== undefined && cartProduct !== null) {
      setQuantity(cartProduct.quantity);
    }
  }

  const defineShowThumbnails = (productInfo) => {
    if (productInfo.imagePathList.length === 1) {
      setShowThumbnails(false);
    }
  }

  const getProductInfo = () => {
    setCarouselImages([]);
    const productId = props.match.params.id;
    axios.get('/api/v1/product/' + productId, {
      headers: {
        Authorization: token,
      },
    }).then(res => {
      const productInfo = res.data.data;
      setProductInfo(productInfo);
      const images = productInfo.imagePathList;
      for (let i = 0; i < images.length; i++) {
        setCarouselImages(carouselImages => [...carouselImages, {
          original: BASE_URL + "/api/v1/product/image/"  + images[i],
          thumbnail: BASE_URL + "/api/v1/product/image/"  + images[i],
        }]);
        defineQuantityFromCart(productInfo.id);
        defineShowThumbnails(productInfo);
      }
      incrementProductViewCount(productInfo.id);
    });
  };

  const onChangeQuantity = (add) => {
    let resultQuantity = add ? quantity + 1 : quantity - 1;
    if (resultQuantity >= 1 && resultQuantity <= productInfo.quantity) {
      setQuantity(resultQuantity);
      setShowTooltip(false);
      if (productIdsInCart.includes(productInfo.id)) {
        const cartProduct = {
          id: productInfo.id,
          image: productInfo.imagePathList[0],
          name: productInfo.name,
          price: productInfo.price,
          discountedPrice: productInfo.discountedPrice,
          quantity: resultQuantity,
          maxQuantity: productInfo.quantity,
          dateAdded: Date.now(),
          toOrder: productInfo.quantity === 0
        }
        const product = { ...cartProduct, quantity: resultQuantity };
        patchProduct(product);
      }
    }
    if (resultQuantity > productInfo.quantity) {
      setShowTooltip(true);
    }
  };

  const handleProductStateChange = (newState) => {
    return axios.put('/api/v1/product', {
      productId: productInfo.id,
      state: newState
    }, {
      headers: {
        Authorization: token,
      },
    }).then(() => {
      getProductInfo();
    }).catch(error => {
      console.error("Error updating product state:", error);
    });
  }

  const renderProductStateForAdmin = () => {
    if (user && user.type === 'ADMIN') {
      return (
        <div className="pb-2">
          <div className='dropdown font-size-20px ml-3 mr-3'>
            <div className={defineProductStateClassName(productInfo.state)}
                 data-toggle='dropdown'
                 aria-expanded='false'>
              {productInfo.state}
            </div>
            <div className='dropdown-menu order-status'>
              {productStatesEnum
                .filter(productState => productState.databaseValue !== productInfo.state)
                .map((productState, index) => (
                  <a
                    key={index}
                    className='dropdown-item'
                    onClick={() => handleProductStateChange(productState.databaseValue)}>
                    {productState.databaseValue}
                  </a>
                ))}
            </div>
          </div>
        </div>
      )
    }
  }

  const renderStatus = () => {
    if (productInfo === undefined) {
      return;
    }

    if (productInfo.quantity > 0 && productInfo.state === 'SOLD_OUT') {
      return (
        <div className='row pl-3'>
          <p className='small text-success font-weight-bold pb-2'>Є в наявності
            <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip'>
              <strong>Товар в наявності на нашому складі, відправка протягом 1 робочого дня</strong>
            </Tooltip>}>
              <i className='bi bi-info-circle px-1' />
            </OverlayTrigger>
          </p>
          <p className='small text-warning font-weight-bold pb-2'>Останні набори
            <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip'>
              <strong>В наявності останні набори. Товар знятий з продажу і більше не буде поставлятись</strong>
            </Tooltip>}>
              <i className='bi bi-info-circle px-1' />
            </OverlayTrigger>
          </p>
        </div>
      );
    } else if (productInfo.quantity > 0 && productInfo.state === 'PREORDER') {
      return (
        <p className='small text-success font-weight-bold pb-2'>Передзамовлення
          <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip'>
            <strong>Товар в передзамовленні, терміни надходження уточнюйте у менеджера</strong>
          </Tooltip>}>
            <i className='bi bi-info-circle px-1' />
          </OverlayTrigger>
        </p>
      )
    } else if (productInfo.quantity > 0) {
      return (
        <p className='small text-success font-weight-bold pb-2'>Є в наявності
          <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip'>
            <strong>Товар в наявності на нашому складі, відправка протягом 1 робочого дня</strong>
          </Tooltip>}>
            <i className='bi bi-info-circle px-1' />
          </OverlayTrigger>
        </p>
      )
    }
    else if (productInfo.state === 'OUT_OF_STOCK') {
      return (
        <p className='small text-warning font-weight-bold pb-2'>Тимчасово недоступний
          <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip'>
            <strong>Товар відсутній на складі у постачальника. Потрібно очікувати на оновлення асортименту</strong>
          </Tooltip>}>
            <i className='bi bi-info-circle px-1' />
          </OverlayTrigger>
        </p>
      );
    } else if (productInfo.state === 'ACTIVE' && productInfo.quantity === 0) {
      return (
        <p className='small text-warning font-weight-bold'>Під замовлення
          <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip'>
            <strong>Товар може бути доставлений під замовлення. Терміни і точну вартість уточнюйте в телеграмі або за
              номером телефону, що вказаний в шапці сайту</strong>
          </Tooltip>}>
            <i className='bi bi-info-circle px-1' />
          </OverlayTrigger>
        </p>
      );
    } else if (productInfo.state === 'SOLD_OUT') {
      return (
        <p className='small text-danger font-weight-bold'>Знятий з продажу
          <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip'>
            <strong>Товар знятий з продажу і навряд чи буде доступний в майбутньому</strong>
          </Tooltip>}>
            <i className='bi bi-info-circle px-1' />
          </OverlayTrigger>
        </p>
      );
    }
  };

  const renderPrice = () => {
    if (productInfo === undefined) {
      return;
    }

    if (productInfo.price === productInfo.discountedPrice) {
      return (
        <h4 className='mr-1'><strong>{productInfo.price}грн</strong></h4>
      );
    } else if (productInfo.discountedPrice < productInfo.price) {
      return (
        <h4>
          <strong>{productInfo.discountedPrice}грн{' '}</strong>
          <strike style={{ textDecoration: 'line-through' }} className='mr-1'>{productInfo.price}грн</strike>{' '}
        </h4>
      );
    }
  };

  const renderLoginInfo = () => {
    if (!isLoggedIn) {
      return (
        <p className='small mb-0'><span>
                  <i className='bi bi-percent font-weight-bold pr-1'></i>
          </span><Link to='/login' className='text-dark font-weight-bold'>Увійдіть </Link>для відображення
          накопичувальної знижки</p>
      );
    }
  };

  const renderDiscountInfo = (showText) => {
    let text = '';
    let tooltipText = '';

    if (productInfo.personalDiscount >= productInfo.productDiscount && isLoggedIn) {
      let discountCategoryText = productInfo.discountCategoryName ? 'Категорія для знижки - ' + productInfo.discountCategoryName : '';
      if (isLoggedIn && productInfo.personalDiscount === 0) {
        tooltipText = 'Для цього товару накопичувальна знижка не застосовується. ' + discountCategoryText;
      } else {
        tooltipText = 'На товар дія ваша накопичувальна знижка. ' + discountCategoryText ;
      }
      if (showText) {
        text = 'Накопичувальна знижка';
      }
      return (
        <p className='small font-weight-bold mb-1'>
          <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip'>
            <strong>{tooltipText}</strong>
          </Tooltip>}>
            <span className='badge badge-dark cursor-pointer mr-1'>
              {productInfo.personalDiscount}%</span>
          </OverlayTrigger>
          {text}
        </p>
      );
    }

    if (productInfo.productDiscount > productInfo.personalDiscount) {
      let tooltipMessage = 'На товар діє акційна знижка';
      if (isLoggedIn) {
        tooltipMessage = tooltipMessage + ', яка більша за вашу персональну знижку ' + productInfo.personalDiscount + ' %';
      }
      if (productInfo.state === 'PREORDER') {
        tooltipMessage = 'На товар діє знижка за передзамовленням';
      }
      if (showText) {
        text = productInfo.state === 'PREORDER' ? 'Знижка за передзамовленням' : 'Акційна знижка';
      }
      return (
        <p className='small font-weight-bold mb-1'>
          <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip'>
            <strong>{tooltipMessage}</strong>
          </Tooltip>}>
            <span className='badge badge-danger cursor-pointer mr-1'>
              {productInfo.productDiscount}%</span>
          </OverlayTrigger>
          {text}
        </p>
      );
    }
  };

  const renderBuyBlock = () => {
    if (productInfo.quantity > 0) {
      return (
        <div className='input-group text-center align-items-center disable-select pt-1'>
                <span
                  onClick={() => onChangeQuantity(false)}
                  className='product-minus bg-dark mr-1'
                > -
                </span>
            <input type='number' id='quantity' name='quantity' className='count'
                   value={quantity}
                   max={productInfo.quantity} />
            <OverlayTrigger placement='top' overlay={showTooltip ? (<Tooltip id='tooltip'>
              <strong>Обрано максимальну кількість, більше немає в наявності</strong>
            </Tooltip>) : (<div/>)}>
            <span
              onClick={() => onChangeQuantity(true)}
              className='product-plus bg-dark ml-1'
            > +
          </span>
            </OverlayTrigger>
          {renderBuyButton()}
        </div>
      );
    } else if (productInfo.quantity === 0 && productInfo.state !== 'SOLD_OUT' && productInfo.state !== 'OUT_OF_STOCK') {
      return (
        <div className='input-group text-center align-items-center disable-select pt-1'>
          <a
            href='https://t.me/waaaghShopAdmin' target='_blank' rel='noopener noreferrer'>
            <button
              type='button'
              className='btn btn-warning'
            >
              Привезти "під замовлення"
            </button>
          </a>
        </div>
      );
    }
  };

  const renderBuyButton = () => {
    if (productIdsInCart.includes(productInfo.id) && productInfo.quantity > 0) {
      return (
        <button type='button' className='btn btn-dark ml-3' onClick={() => Toggle()}>
          Оформити
        </button>
      );
    }
    if (productInfo.quantity > 0 && !productIdsInCart.includes(productInfo.id)) {
      return (
        <button
          type='button'
          onClick={() => addProductToCart(productInfo, quantity)}
          className='btn btn-success ml-3'
        >
          Додати в кошик
        </button>
      );
    }
  };

  if (!productInfo) {
    return <div>Завантаження сторінки...</div>;
  }

  return (
    <div className='container'>
      <Helmet>
        <title>{`Купити ${productInfo.name} з доставкою по Україні.`}</title>
        <meta name='description'
              content={`Купити ${productInfo.name} за ціною ${productInfo.discountedPrice} грн. Діє накопичувальна система знижок та безкоштовна доставка по Україні`} />

        {/* Open Graph метатеги */}
        <meta property='og:title' content={`Купити ${productInfo.name} з доставкою по Україні.`} />
        <meta property='og:description'
              content={`Купити ${productInfo.name} за ціною ${productInfo.discountedPrice} грн. Діє накопичувальна система знижок та безкоштовна доставка по Україні`} />
        <meta property='og:image'
              content={productInfo.imagePathList && productInfo.imagePathList.length > 0 ? `${BASE_URL}/api/v1/product/image/${productInfo.imagePathList[0]}` : ''} />
        <meta property='og:url' content={window.location.href} />
        <meta property='og:type' content='product' />
        <meta property='og:site_name'
              content='Waaagh Shop - магазин мініатюр та аксесуарів для Warhammer, Vallejo, Forge World, Age of Sigmar, Kill Team, Warcry та інших систем.' />

        {/* Twitter метатеги */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={`Купити ${productInfo.name} з доставкою по Україні.`} />
        <meta name='twitter:description'
              content={`Купити ${productInfo.name} за ціною ${productInfo.discountedPrice} грн. Діє накопичувальна система знижок та безкоштовна доставка по Україні`} />
        <meta name='twitter:image'
              content={productInfo.imagePathList && productInfo.imagePathList.length > 0 ? `${BASE_URL}/api/v1/product/image/${productInfo.imagePathList[0]}` : ''} />
        <meta name='twitter:url' content={window.location.href} />
      </Helmet>
      <div className='row'>
        <div className='col p-0'>
          <Header />
        </div>
      </div>

      <div className='row'>

        <div className='col-xl-6 col-lg-6 col-md-6 col-12 product-images-block'>
          <ImageGallery items={carouselImages} infinite={false} showPlayButton={false} showThumbnails={showThumbnails}
                        lazyload={true} loading={'lazy'} />
          {productInfo.state === 'PREORDER' && (
            <span className='badge badge-success preorder-badge product-badge d-none d-lg-block'>Передзамовлення</span>
          )}
          {productInfo.discountCategoryName === 'Webstore Exclusive GW' && (
            <span className='badge badge-secondary product-badge online-only-badge'>Online Only</span>
          )}
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
          <h4 className='font-weight-bold pt-5 mb-0'>{productInfo.name}</h4>
          <div className="d-flex">
            {renderStatus()}
            {renderProductStateForAdmin()}
          </div>

          <div className="row price-block bg-light pt-2">
            <div className='col-xl-6 col-lg-6 col-12'>
              <div className='container-fluid d-flex p-0 pt-2'>
                {renderPrice()}
                {renderDiscountInfo(false)}
              </div>
              {renderDiscountInfo(true)}
              <div className="d-lg-none">
                {renderLoginInfo()}
              </div>
            </div>

            <div className='col-xl-6 col-lg-6 col-12'>
              {renderBuyBlock()}
            </div>
          </div>

          <div className="row bg-light d-none d-lg-block">
            <div className="col">
              {renderLoginInfo()}
            </div>
          </div>

          <hr className='hr-divider' />

          {productInfo.description !== undefined && productInfo.description !== '' ? (
            <div>
              <h6><strong>Опис і комплектація:</strong></h6>
              <div className='small'
                   dangerouslySetInnerHTML={{
                     __html: productInfo.description,
                   }}
              />
            </div>) : null}

          {productInfo.categories !== undefined ?
            <div className='font-weight-bold small pt-3'>Категорії:
              {productInfo.categories.map(category => {
                return (
                  <Link key={category.id} to={'/boxes?categoryIds=' + category.id}> {category.name}</Link>
                );
              })}
            </div>
            : null}
          {productInfo.code !== undefined && productInfo.code !== null ?
            <p className='small mb-0'><strong>Артикул: </strong>{productInfo.code}</p>
            : null}
          {isLoggedIn && productInfo.discountCategoryName && (
            <p className='small'><strong>Категорія для персональної знижки: </strong>{productInfo.discountCategoryName}</p>
          )}

          <div className='info-block pt-3'>
            <Tabs
              defaultActiveKey=''
              id='information-tabs'
              className='mb-3 product-page-info-tabs black-text'
            >
              <Tab eventKey='delivery' title='Доставка'>
                <div className='delivery-info small'>
                  <p><strong>Доставка:</strong></p>
                  <ol>
                    <li>
                      Доставка до відділення чи поштомату Нової Пошти.
                    </li>
                    <li>
                      Кур'єрська доставка Новою Поштою. При оформленні замовлення вкажіть адресу в полі "Коментар
                      до замовлення"
                    </li>
                    <li>
                      Доставка Укрпоштою. Здійснюється з затримкою в 1 робочий день
                    </li>
                  </ol>
                  <p><strong>Доставка є безкоштовною для покупця за умови покупки на суму більшу за 2000грн і повної
                    передплати</strong></p>
                  <p><strong>Відправка замовлення</strong> здійснюється в той же день (для відправок Новою Поштою), якщо
                    воно
                    зроблено не пізніше, ніж за 4год до кінця робочого дня. Актуальний графік роботи магазину
                    можна переглянути в шапці сайту. Замовлення укрпоштою відправляються з затримкою в 1 день</p>
                </div>
              </Tab>
              <Tab eventKey='payment' title='Оплата'>
                <div className='payment-info small'>
                  <p><strong>Оплата</strong> може бути здійснена наступними способами:</p>
                  <ul>
                    <li><strong>Оплата на банківський рахунок.</strong> У цьому випадку реквізити будуть надані
                      менеджером після обробки вашого замовлення
                    </li>
                    <li><strong>Післяплата на пошті.</strong> Оплата відбувається при отриманні у відділенні Нової
                      Пошти / Укрпошти. Службою доставка стягується додатковка комісія до 2%
                    </li>
                  </ul>
                </div>
              </Tab>
            </Tabs>
          </div>

        </div>
      </div>
      <hr />
      <div className='row'>
        <div className='col-12'>
        </div>
      </div>

      <div className='row p-0'>
        <div className='col p-0'>
          <Footer />
        </div>
      </div>
      <Basket showModal={showModal} close={Toggle} />
    </div>
  );
};

export default ProductPage;